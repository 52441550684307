@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
thead {
  height: 40px;
}
td {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Custome CSS */
.input-p2p {
  border: none;
  border-bottom: 1px solid #919191;
  border-radius: 0%;
  font-weight: 500;
  padding-bottom: 8px;
  width: 100%;
}
.input-p2p:focus {
  outline: none;
  border-bottom: 2px solid #6a55ea;
}

.navbar-label {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-transform: capitalize;

  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.navbar-label-active {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* text-align: right; */
  text-transform: capitalize;

  /* White */

  color: #ffffff;
}

.buttons-label {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* text-align: center; */
  text-transform: capitalize;

  /* Gradient */

  background: linear-gradient(105.62deg, #5f467b 21.35%, #4369b0 61.18%);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #ffffff;
}
